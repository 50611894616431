<template>
    <v-container>
        <v-row class="mb-6" no-gutters>
            <v-list-item>
                <v-list-item-avatar :size="$vuetify.breakpoint.xsOnly ? 50 : 120">
                    <v-img :src="getUserAvatar"/>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title class="title">{{ getUserName }}</v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1 uppercase__first">{{ getRoles }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-row>

        <div v-if="loading" class="d-flex align-center flex-column mt-8 mb-8">
            <v-progress-circular
                :size="50"
                color="blue-grey darken-4"
                indeterminate/>
            <p class="subtitle-1 mt-6">{{ $t(`messages['loading data']`) }}</p>
        </div>

        <table-error v-show="failed" @again="loadDashboard()"/>

        <div v-if="canDisplay">
            <div class="mb-5">
                <p class="pl-4">
                    {{ $t(`actions['specify range']`) }}
                    <v-btn @click="onClickSelectRange()" icon>
                        <v-icon>mdi-calendar</v-icon>
                    </v-btn>
                </p>
            </div>

            <v-row class="mb-6">
                <v-col cols="12" sm="6" md="4" class="pr-2" v-if="isPhotographer">
                    <stats-card
                        color="green"
                        icon="mdi-camera"
                        :subtitle="$t(`roles['photographer']`)"
                        :values="[
                            {label: $t(`rudiments.made`), value: cardPhotographer.uploaded},
                            {label:$t(`rudiments['sold']`), value: cardPhotographer.sales},
                            {label:$t(`rudiments['my earnings']`), value: `${$options.filters.currency(cardPhotographer.salary)}`},
                        ]"
                        sub-icon="mdi-calendar"
                        :sub-text="cardsRange"/>
                </v-col>
                <!--                <v-col cols="12" sm="6" md="4" class="pr-2" v-if="isPlaceAdmin">-->
                <!--                    <stats-card-->
                <!--                            color="red"-->
                <!--                            icon="mdi-map-marker"-->
                <!--                            :subtitle="this.$t(`roles['admin']`)"-->
                <!--                            :values="[-->
                <!--                            {label:'Анкет собрано', value: cardAdmin.worksheetsCreated},-->
                <!--                            {label: '&nbsp;', value: '&nbsp;'},-->
                <!--                            {label:'Покупок по анкетам', value: cardAdmin.sales},-->
                <!--                        ]"-->
                <!--                            sub-icon="mdi-calendar"-->
                <!--                            :sub-text="cardsRange"/>-->
                <!--                </v-col>-->
                <v-col cols="12" sm="6" md="4" class="pr-2" v-if="isSeller || isPlaceAdmin">
                    <stats-card
                        color="orange"
                        icon="mdi-currency-usd"
                        :subtitle="$t(`roles['seller']`)"
                        :values="[
                            {label:$t(`rudiments['sold']`), value: cardSeller.sales},
                            {label: '&nbsp;', value: '&nbsp;'},
                            {label:$t(`rudiments['my earnings']`), value: `${cardSeller.salary}Р`},
                        ]"
                        sub-icon="mdi-calendar"
                        :sub-text="$t(`rudiments['last day']`)"/>
                </v-col>
            </v-row>


            <v-row class="mb-6">
                <v-col cols="12" :md="chartCols" class="pr-2" v-if="isPhotographer">
                    <chart-card
                        v-model="chartPhotographer"
                        :title="$t(`roles['photographer']`)"
                        :sub-text="chartsRange"
                        sub-icon="mdi-calendar"
                        color="green">

                    </chart-card>
                </v-col>
                <!--                <v-col cols="12" :md="chartCols" class="pr-2" v-if="isPlaceAdmin">-->
                <!--                    <chart-card-->
                <!--                            v-model="chartAdmin"-->
                <!--                            :title="$t(`roles['admin']`)"-->
                <!--                            :sub-text="chartsRange"-->
                <!--                            sub-icon="mdi-calendar"-->
                <!--                            color="red">-->
                <!--                    </chart-card>-->
                <!--                </v-col>-->
                <v-col cols="12" :md="chartCols" class="pr-2" v-if="isSeller || isPlaceAdmin">
                    <chart-card
                        v-model="chartSeller"
                        :title="$t(`roles['seller']`)"
                        :sub-text="chartsRange"
                        sub-icon="mdi-calendar"
                        color="orange">
                    </chart-card>
                </v-col>
                <!--            <v-col cols="4" class="ml-2">-->
                <!--                <chart-card-->
                <!--                        v-model="chartLandLord"-->
                <!--                        title="Арендодатель"-->
                <!--                        color="yellow">-->
                <!--                </chart-card>-->
                <!--            </v-col>-->
            </v-row>
        </div>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import StatsCard from "../components/StatsCard"
import ChartCard from "../components/ChartCard"
import TableError from "../components/TableError"
import dayjs from "dayjs"
import * as Format from "@/util/format"

const sortDashboardValues = (items) => items.sort((a, b) => a.begin - b.begin)
const chartFormatDate = (ts) => (dayjs(ts * 1000).format("DD.MM"))

export default {
    components: { StatsCard, ChartCard, TableError },
    head() {
        return {
            title: this.$t("titles.dashboard"),
            toolbar: this.$t("toolbar.dashboard"),
        }
    },

    fetch({ store, redirect }) {
        if (!store.getters["common/isAuthorized"]) {
            return redirect("/login")
        }
    },

    data() {
        return {
            dates: [],
            loading: true,
            pickerDialog: false,
            failed: false,
        }
    },

    created() {
        this.loadDashboard()
    },

    computed: {
        ...mapGetters("common", ["getUser", "isPlaceAdmin", "isPhotographer", "isJesus", "isSeller"]),
        ...mapGetters("user", ["getDashboard"]),
        canDisplay() {
            return !this.failed && !this.loading
        },

        getUserName() {
            const { First, Last } = (this.getUser || {}).Name || {}
            return `${Format.getName(First)} ${Format.getName(Last)}`
        },

        getUserAvatar() {
            const { Cropped } = (this.getUser || {}).Avatar || {}
            return Format.getMedia(Cropped)
        },

        getRoles() {
            return [
                this.isPhotographer ? this.$t(`roles['photographer']`) : "",
                this.isPlaceAdmin ? this.$t(`roles['admin']`) : "",
            ].filter(part => part !== "").join(", ")
        },

        cardPhotographer() {
            return this.getDashboard.daily.reduce((res, stat) => {
                res.uploaded += stat.AsPhotographer.PhotosMade
                res.sales += stat.AsPhotographer.PhotosSale
                res.salary += stat.AsPhotographer.Profit
                return res
            }, { uploaded: 0, sales: 0, salary: 0 })
        },

        cardAdmin() {
            return this.getDashboard.daily.reduce((res, stat) => {
                res.worksheetsCreated += stat.AsAdmin.WorksheetCreated
                res.sales += stat.AsAdmin.ByWorksheetSale
                res.salary += stat.AsAdmin.PlacesProfit
                return res
            }, { worksheetsCreated: 0, sales: 0, salary: 0 })
        },

        cardSeller() {
            return this.getDashboard.daily.reduce((res, stat) => {
                res.sales += stat.AsSeller.PhotosSale
                res.salary += stat.AsSeller.Profit
                return res
            }, { sales: 0, salary: 0 })
        },

        chartPhotographer() {
            const defaultValue = [
                { name: this.$t(`rudiments['uploaded']`), data: [], color: "#ffffff" },
                { name: this.$t(`rudiments.sold`), data: [], color: "#f38b47" },
                { name: this.$t(`rudiments['earnings']`), data: [], color: "#f05b4f" },
            ]
            const data = this.getDashboard.chart.reduce((res, stat) => {
                const date = chartFormatDate(stat.DateBegin)
                const values = stat.AsPhotographer

                res[0].data.push({ y: values.PhotosMade, label: date, begin: stat.DateBegin })
                res[1].data.push({ y: values.PhotosSale, label: date, begin: stat.DateBegin })
                res[2].data.push({ y: values.Profit, tooltipText: `${values.Profit}р`, label: date, begin: stat.DateBegin })
                return res
            }, defaultValue)

            return data.map(part => {
                part.data = sortDashboardValues(part.data)
                return part
            })
        },

        chartAdmin: function() {
            const defaultValue = [
                { name: this.$t(`rudiments['worksheets got']`), data: [], color: "#ffffff" },
                { name: this.$t(`rudiments['worksheets sold']`), data: [], color: "#f4c63d" },
            ]

            const data = this.getDashboard.chart.reduce((res, stat) => {
                const date = chartFormatDate(stat.DateBegin)
                res[0].data.push({ y: stat.AsAdmin.WorksheetCreated, label: date, begin: stat.DateBegin })
                res[1].data.push({ y: stat.AsAdmin.ByWorksheetSale, label: date, begin: stat.DateBegin })
                return res
            }, defaultValue)

            return data.map(part => {
                part.data = sortDashboardValues(part.data)
                return part
            })
        },

        chartSeller() {
            const defaultValue = [
                { name: this.$t(`rudiments['worksheets got']`), data: [], color: "#ffffff" },
                { name: this.$t(`rudiments['worksheets sold']`), data: [], color: "#f4c63d" },
            ]

            const data = this.getDashboard.chart.reduce((res, stat) => {
                const date = chartFormatDate(stat.DateBegin)
                res[0].data.push({ y: stat.AsSeller.WorksheetCreated, label: date, begin: stat.DateBegin })
                res[1].data.push({ y: stat.AsSeller.ByWorksheetSale, label: date, begin: stat.DateBegin })
                return res
            }, defaultValue)

            return data.map(part => {
                part.data = sortDashboardValues(part.data)
                return part
            })
        },

        chartLandLord() {
            const defaultValue = [
                { name: this.$t(`rudiments['of sales']`), data: []},
                { name: this.$t(`rudiments['earned']`), data: [], color: "green" },
            ]

            const data = this.getDashboard.chart.reduce((res, stat) => {
                const date = chartFormatDate(stat.DateBegin)
                res[0].data.push({ y: stat.AsLandLord.PhotosSale, label: date, begin: stat.DateBegin })
                res[1].data.push({ y: stat.AsLandLord.Profit, label: date, begin: stat.DateBegin })
                return res
            }, defaultValue)

            return data.map(part => {
                part.data = sortDashboardValues(part.data)
                return part
            })
        },

        getMinRange() {
            return dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        },

        getMaxRange() {
            return dayjs().format("YYYY-MM-DD")
        },

        cardsRange() {
            if (this.getDashboard.useDefaults) {
                return this.$t(`rudiments['last day']`)
            }

            const { begin, end } = this.getDashboard.dailyRange
            return Format.dateRange(begin, end, "DD.MM.YYYY")
        },

        chartsRange() {
            if (this.getDashboard.useDefaults) {
                return this.$t(`rudiments['last week']`)
            }

            const { begin, end } = this.getDashboard.weeklyRange
            return Format.dateRange(begin, end, "DD.MM.YYYY")
        },

        chartCols() {
            const points = this.getDashboard.chart.length
            if (points > 32) {
                return 12
            } else if (points > 15) {
                return 6
            }

            return 4
        },
    },

    methods: {
        ...mapActions("user", ["fetchDashboard"]),
        onClickSelectRange() {
            this.$dialog.datepicker({
                title: this.$t(`messages['choose range']`),
                value: this.dates,
                min: this.getMinRange,
                max: this.getMaxRange,
                range: true,
                clearable: true,
            }).then(res => {
                if (res === null) {
                    return
                }

                if (res === "clear") {
                    this.dates = []
                } else {
                    this.dates = res
                }
                this.loadDashboard()
            })
        },

        loadDashboard() {
            const range = { begin: null, end: null }
            this.failed = false
            this.loading = true

            if (this.dates.length > 0) {
                const dates = [dayjs(this.dates[0], "YYYY-MM-DD"), dayjs(this.dates[1], "YYYY-MM-DD")]
                    .sort((a, b) => a.unix() - b.unix())

                range.begin = dates[0].startOf("day")
                range.end = dates[1].endOf("day")
            }

            this.fetchDashboard(range)
                .catch(() => {
                    this.failed = true
                })
                .finally(() => this.loading = false)
        },
    },
}
</script>

<style>
    .uppercase__first:first-letter {
        text-transform: uppercase;
    }

    .dashboard-page__avatar-overlay {
        background-color: hsla(0, 0%, 100%, .6);
    }
</style>
