var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"mb-6",attrs:{"no-gutters":""}},[_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"size":_vm.$vuetify.breakpoint.xsOnly ? 50 : 120}},[_c('v-img',{attrs:{"src":_vm.getUserAvatar}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title"},[_vm._v(_vm._s(_vm.getUserName))]),_c('v-list-item-subtitle',{staticClass:"subtitle-1 uppercase__first"},[_vm._v(_vm._s(_vm.getRoles))])],1)],1)],1),(_vm.loading)?_c('div',{staticClass:"d-flex align-center flex-column mt-8 mb-8"},[_c('v-progress-circular',{attrs:{"size":50,"color":"blue-grey darken-4","indeterminate":""}}),_c('p',{staticClass:"subtitle-1 mt-6"},[_vm._v(_vm._s(_vm.$t(`messages['loading data']`)))])],1):_vm._e(),_c('table-error',{directives:[{name:"show",rawName:"v-show",value:(_vm.failed),expression:"failed"}],on:{"again":function($event){return _vm.loadDashboard()}}}),(_vm.canDisplay)?_c('div',[_c('div',{staticClass:"mb-5"},[_c('p',{staticClass:"pl-4"},[_vm._v(" "+_vm._s(_vm.$t(`actions['specify range']`))+" "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onClickSelectRange()}}},[_c('v-icon',[_vm._v("mdi-calendar")])],1)],1)]),_c('v-row',{staticClass:"mb-6"},[(_vm.isPhotographer)?_c('v-col',{staticClass:"pr-2",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('stats-card',{attrs:{"color":"green","icon":"mdi-camera","subtitle":_vm.$t(`roles['photographer']`),"values":[
                        {label: _vm.$t(`rudiments.made`), value: _vm.cardPhotographer.uploaded},
                        {label:_vm.$t(`rudiments['sold']`), value: _vm.cardPhotographer.sales},
                        {label:_vm.$t(`rudiments['my earnings']`), value: `${_vm.$options.filters.currency(_vm.cardPhotographer.salary)}`},
                    ],"sub-icon":"mdi-calendar","sub-text":_vm.cardsRange}})],1):_vm._e(),(_vm.isSeller || _vm.isPlaceAdmin)?_c('v-col',{staticClass:"pr-2",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('stats-card',{attrs:{"color":"orange","icon":"mdi-currency-usd","subtitle":_vm.$t(`roles['seller']`),"values":[
                        {label:_vm.$t(`rudiments['sold']`), value: _vm.cardSeller.sales},
                        {label: '&nbsp;', value: '&nbsp;'},
                        {label:_vm.$t(`rudiments['my earnings']`), value: `${_vm.cardSeller.salary}Р`},
                    ],"sub-icon":"mdi-calendar","sub-text":_vm.$t(`rudiments['last day']`)}})],1):_vm._e()],1),_c('v-row',{staticClass:"mb-6"},[(_vm.isPhotographer)?_c('v-col',{staticClass:"pr-2",attrs:{"cols":"12","md":_vm.chartCols}},[_c('chart-card',{attrs:{"title":_vm.$t(`roles['photographer']`),"sub-text":_vm.chartsRange,"sub-icon":"mdi-calendar","color":"green"},model:{value:(_vm.chartPhotographer),callback:function ($$v) {_vm.chartPhotographer=$$v},expression:"chartPhotographer"}})],1):_vm._e(),(_vm.isSeller || _vm.isPlaceAdmin)?_c('v-col',{staticClass:"pr-2",attrs:{"cols":"12","md":_vm.chartCols}},[_c('chart-card',{attrs:{"title":_vm.$t(`roles['seller']`),"sub-text":_vm.chartsRange,"sub-icon":"mdi-calendar","color":"orange"},model:{value:(_vm.chartSeller),callback:function ($$v) {_vm.chartSeller=$$v},expression:"chartSeller"}})],1):_vm._e()],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }