<template>
    <mdl-card class="chart-card" v-bind="$attrs" v-on="$listeners">
        <h4 class="title font-weight-light">
            {{title}}
        </h4>

        <template slot="header">
            <div class="chart-card__chart" style="max-height: 156px">
                <dynamic-chart :background="$attrs.color" :options="getOptions" v-model="value" theme="md"/>
            </div>
        </template>

        <template slot="actions">
            <v-icon class="mr-2" size="20">
                {{subIcon || "mdi-clock-outline"}}
            </v-icon>
            <span class="caption font-weight-light">
                {{ subText }}
            </span>
        </template>
    </mdl-card>
</template>
<script>
import MdlCard from "./MdlCard"
import DynamicChart from "./charts/DynamicChart"

export default {
    inheritAttrs: false,
    components: { MdlCard, DynamicChart },
    props: {
        title: String,
        subText: String,
        subIcon: String,
        value: Array,
    },
    computed: {
        getOptions() {
            return {
                plotOptions: {
                    series: {
                        showInLegend: false,
                        label: { connectorAllowed: false },
                    },
                },
                tooltip: {
                    formatter: function() {
                        return `
            <b>${this.point.label}</b><br>
            ${this.series.name}:<b>${this.point.tooltipText || this.point.y}</b>
        `
                    },
                },
            }
        },
    },
}
</script>
<style lang="stylus">
    .chart-card .dynamic-chart {
        padding 0
    }
</style>
