<template>
    <material-card
        v-bind="$attrs"
        v-on="$listeners"
        class="v-card--material-stats">
        <v-card
            slot="offset"
            :class="`elevation-${elevation}`"
            :color="color"
            class="d-flex py-1 px-2 caption"
            dark>
            <v-icon size="18" class="mr-2">
                {{ icon }}
            </v-icon>
            <span>{{subtitle}}</span>
        </v-card>

        <div class="pt-2">
            <div v-for="(value, i) in values" :key="i">
                <div class="text-left d-flex align-center">
                    <p class="category mb-0" v-html="value.label"></p>
                    <h3 class="title flex text-right display-1 font-weight-light" v-html="value.value"></h3>
                </div>
            </div>
        </div>

        <template slot="actions">
            <v-icon :color="subIconColor" size="20" class="mr-2">{{ subIcon }}</v-icon>
            <span :class="subTextColor" class="caption font-weight-light">{{subText}}</span>
        </template>
    </material-card>
</template>

<script>
import MdlCard from "./MdlCard"

export default {
    inheritAttrs: false,
    components: { MaterialCard: MdlCard },

    props: {
        ...MdlCard.props,
        icon: {
            type: String,
            required: true,
        },
        subIcon: {
            type: String,
            default: undefined,
        },
        subtitle: {
            type: String,
            default: undefined,
        },
        subIconColor: {
            type: String,
            default: undefined,
        },
        subTextColor: {
            type: String,
            default: undefined,
        },
        subText: {
            type: String,
            default: undefined,
        },
        title: {
            type: String,
            default: undefined,
        },
        values: {
            type: Array,
            default: () => [],
        },
    },
}
</script>

<style lang="scss">
    .v-card--material-stats {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin: 0 !important;

        .v-offset {
            display: inline-block;
            flex: 0 1;
            margin-top: 8px;
            margin-left: 0;
            margin-right: auto;
            margin-bottom: 0 !important;
            max-width: auto;
            padding: 0 16px 0;
            position: absolute;
        }

        .v-card {
            border-radius: 4px;
            flex: 0 1 auto;
        }

        .v-card__text {
            top: 0;
            right: 0;
            width: 100%;
            padding-bottom: 0;
        }

        .v-card__actions {
            flex: 1 0 100%;
        }
    }
</style>
